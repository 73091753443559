// Constants
import { ADDONS, PACKAGES, ROLES, TRIAL_DAYS } from '@/constants'
// Vue
import Vue from 'vue'
// Components
import CardContainer from '@/components/ui/CardContainer'
import DataColumns from '@/components/ui/DataColumns'
import PlansTrialVersion from '@/components/elements/plans/PlansTrialVersion'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapGetters, mapState } from 'vuex'
// Services
import { getAnalyticsDataByPlaceId, getOrdersDataForDashboardByPlaceId } from '@/services/place'
// Filters
import { formatNumber, formatDate, formatPrice } from '@/filters'
// Utils
import { get, isNil } from 'lodash'
import { getEnvironmentVariable, randomNumber } from '@/utils'
// Charts
import VueApexCharts from 'vue-apexcharts'
// Charts
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

const moment = require('moment')
// Days to date selector
const sevenDaysAgo = moment().subtract(7, 'days').format('YYYY-MM-DD')
const fifteenDaysAgo = moment().subtract(15, 'days').format('YYYY-MM-DD')
const thirtyDaysAgo = moment().subtract(30, 'days').format('YYYY-MM-DD')

export default {
  name: 'Dashboard',
  components: {
    CardContainer,
    DataColumns,
    VuetifyContentLoading,
    VuetifyToolBar
  },
  mixins: [addonsMixin, uiMixin],
  data() {
    return {
      // data
      visitStatistics: [],
      orderStatistics: [],
      // Chart
      orderSeries: [],
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          }
        },
        noData: {
          text: 'No hay datos que cargar en las fechas determinadas'
        }
      },
      // options
      dateSelected: sevenDaysAgo,
      dateOptions: [
        {
          label: '7 días',
          value: sevenDaysAgo
        },
        {
          label: '15 días',
          value: fifteenDaysAgo
        },
        {
          label: '30 días',
          value: thirtyDaysAgo
        }
      ],
      // others
      processingRequest: false
    }
  },
  computed: {
    ...mapGetters('place', ['placeData', 'currencyData', 'subscribedPackages']),
    ...mapGetters('company', ['trialPlan']),
    ...mapGetters('authentication', ['userData']),
    ...mapState('app', ['extraSmallDevice']),
    /**
     * Tenemos posibilidad de hacer un uso de un "trial"
     *
     * @return {boolean}
     */
    hasTrialOption() {
      return isNil(this.trialPlan) && this.subscribedPackages.length === 0
    },
    /**
     * El establecimiento no tiene paquete contratado
     *
     * @return {boolean}
     */
    isFree() {
      return this.subscribedPackages.length === 0
    },
    /**
     * Etiqueta del botón "Contratar Lite"
     */
    labelActionButton() {
      return this.hasTrialOption ? `Prueba ${TRIAL_DAYS} días ¡gratis!` : 'Contratar plan PRO'
    },
    /**
     * Tiene al menos un paquete mayor a "Lite"
     */
    moreThanLite() {
      return this.hasAdvancePackage || this.hasProPackage
    },
    /**
     * Opciones del botón de contratación
     *
     * @return {object}
     */
    optionsBillingButton() {
      if (this.trialPlan && this.trialPlan.daysLeft > -1) {
        return {
          buttonLabel: 'Completar datos de facturación',
          buttonLink: '/billing',
          text: `Quedan <span class="red--text">${this.trialPlan.daysLeft}</span> días del periodo de
                pruebas`
        }
      } else if (!this.isFree) {
        const pack = get(this.subscribedPackages, '0.packageId', 'pro')
        return {
          buttonLabel: `Contratar plan ${PACKAGES[pack].label}`,
          buttonLink: `/checkout/${pack}`,
          text: `Tu periodo de prueba del plan ${PACKAGES[pack].label} <span class="red--text">ha caducado</span>`
        }
      }

      return {}
    },
    /**
     * Botón para completar datos de facturación ( o contratar)
     *
     * @return {boolean}
     */
    showBillingButton() {
      // Esta variable es para todos aquellos que posean suscripciones
      // puestas por nosotros a mano
      const isCustomSuscription = !get(this.subscribedPackages, '0.stripeSubscriptionId', null)
      const companyConfig = get(this.companyAddonsSetupByUser, ADDONS.company, {})
      const stripeCustomerId = get(companyConfig, 'stripeCustomerId', null)
      const card = get(companyConfig, 'card', null)

      return (
        ((this.trialPlan && this.trialPlan.daysLeft > -1) || !this.isFree) &&
        (!stripeCustomerId || !card) &&
        !isCustomSuscription &&
        this.userData.role === ROLES.horeca_admin.value &&
        getEnvironmentVariable('VUE_APP_BRAND') !== 'schweppes'
      )
    }
  },
  watch: {
    dateSelected() {
      this.getEveryNeededData()
    }
  },
  async mounted() {
    // Obtenemos todos los datos necesarios de la APP
    await this.getEveryNeededData()
    // Capturamos evento
    this.$root.$on('reload-dashboard-data', this.getEveryNeededData)
  },
  destroyed() {
    // Paramos escucha
    this.$root.$off('reload-dashboard-data')
  },
  methods: {
    /**
     * Acción tras pulsar sobre botón "Contratar Lite"
     */
    handleActionButton() {
      if (this.hasTrialOption) {
        // Modal de trial
        this.modifyAppDialog({
          hideTitle: true,
          contentComponent: PlansTrialVersion,
          contentComponentProps: {
            id: 'pro'
          },
          hideActionButtons: true,
          visible: true
        })
      } else {
        // Planes para contratar
        this.routerPushTo({
          path: '/checkout/pro'
        })
      }
    },
    /**
     * Obtenemos todos los datos a mostrar en el componente
     */
    async getEveryNeededData() {
      try {
        // Loading
        this.processingRequest = true
        // Obtenemos todos los datos
        await this.setVisitStatistics()
        // Los datos de pedidos están disponibles solo en versión "pro" y "advance"
        if (this.moreThanLite) {
          await this.setOrderStatistics()
        }
      } catch (error) {
        this.modifyAppAlert({
          type: 'error',
          text: error.message,
          visible: true
        })
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Obtenemos datos de visitas
     */
    async setVisitStatistics() {
      const data = this.isFree
        ? {
            totalUsers: randomNumber(1000),
            totalUsersIncrementPeriods: randomNumber(100),
            totalSessions: randomNumber(500),
            totalSessionsIncrementPeriods: randomNumber(100),
            totalVisitsQR: randomNumber(1000),
            totalVisitsQRIncrementPeriods: randomNumber(100),
            totalVisitsURL: randomNumber(1000),
            totalVisitsURLIncrementPeriods: randomNumber(100)
          }
        : await getAnalyticsDataByPlaceId(
            this.placeData.id,
            this.dateSelected,
            moment().format('YYYY-MM-DD')
          )

      this.visitStatistics = [
        {
          label: 'Usuarios',
          value: formatNumber(get(data, 'totalUsers', 0)),
          ratio: get(data, 'totalUsersIncrementPeriods', 0)
        },
        {
          label: 'Visitas totales',
          value: formatNumber(get(data, 'totalSessions', 0)),
          ratio: get(data, 'totalSessionsIncrementPeriods', 0)
        },
        {
          label: 'Visitas QR (establecimiento)',
          value: formatNumber(get(data, 'totalVisitsQR', 0)),
          ratio: get(data, 'totalVisitsQRIncrementPeriods', 0)
        },
        {
          label: 'Visitas Enlace (otros medios)',
          value: formatNumber(get(data, 'totalVisitsURL', 0)),
          ratio: get(data, 'totalVisitsURLIncrementPeriods', 0)
        }
      ]
    },
    /**
     * Obtenemos datos de pedidos
     */
    async setOrderStatistics() {
      const daysAgo = moment().diff(moment(this.dateSelected), 'days')
      // Datos de pedidos
      const { periodOrdersData, salesData } = await getOrdersDataForDashboardByPlaceId(
        this.placeData.id,
        daysAgo
      )
      // Columns
      if (!isNil(salesData)) {
        this.orderStatistics = [
          {
            label: 'Pedidos totales',
            value: formatNumber(get(salesData, 'totalOrders', 0), true),
            ratio: get(salesData, 'totalOrdersIncrement', 0)
          },
          {
            label: 'Ticket medio',
            value: formatPrice(
              get(salesData, 'averageTicket', 0),
              get(this.currencyData, 'code', 'EUR'),
              true
            ),
            ratio: get(salesData, 'averageTicketIncrement', 0)
          },
          {
            label: 'Ventas totales',
            value: formatPrice(
              get(salesData, 'totalAmount', 0),
              get(this.currencyData, 'code', 'EUR'),
              true
            ),
            ratio: get(salesData, 'totalAmountIncrement', 0)
          }
        ]
      } else {
        this.orderStatistics = []
      }

      // Chart
      if (!isNil(periodOrdersData) && periodOrdersData.length > 0) {
        const parseData = periodOrdersData.reduce(
          (sumItems, item) => {
            // Parseamos fecha
            const partsDate = item.createdDate.split('/')
            const parsedDate = `${partsDate[2]}-${partsDate[1]}-${partsDate[0]}`

            // Agrupamos valores en sus objetos
            sumItems.averageTickets = [...sumItems.averageTickets, formatNumber(item.averageTicket)]
            sumItems.dates = [...sumItems.dates, formatDate(parsedDate, 'D MMM')]
            sumItems.totalOrders = [...sumItems.totalOrders, item.totalOrders]
            return sumItems
          },
          {
            averageTickets: [],
            dates: [],
            totalOrders: []
          }
        )
        // Eje Y
        this.orderSeries = [
          {
            name: 'Pedidos',
            data: parseData.totalOrders
          },
          {
            name: 'Ticket medio',
            data: parseData.averageTickets
          }
        ]
        // Eje X
        this.chartOptions.xaxis = {
          categories: parseData.dates
        }
      } else {
        this.orderSeries = []
      }
    }
  }
}
